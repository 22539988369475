<template>
	<div>
		<CustomerForm @cancel="goToList" @submit="createCustomer" />
	</div>
</template>

<script>
import { CREATE_CUSTOMER } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("customers")

export default {
	name: "CreateCustomer",
	components: {
		CustomerForm: () => import("../components/CustomerForm"),
	},

	methods: {
		...mapActions({ CREATE_CUSTOMER }),
		createCustomer: async function (params) {
			await this.CREATE_CUSTOMER(params)
		},
		goToList() {
			this.$router.push({ name: "CustomerManagement" })
		},
	},
}
</script>
